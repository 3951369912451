.btns {
  display: flex;
  width: 100%;
  height: 20%;
  justify-content: space-evenly;
  align-items: center;
}

.btn {
  min-width: 1px !important;
  width: 90px !important;
  display: flex !important;
  justify-content: center;
  align-items: center !important;
}