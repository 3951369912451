.container {
  display: flex;
}


.card {
  height: 100%;
  width: 100%;
}

.animation {
  width: 100%;
  height: 75%;
  border-bottom: 1px solid var(--accents-2);
}

.description {
  width: 100%;
  height: 25%;
  padding: 1rem 1.5rem;
}

.table {
  width: 100%;
  height: 90%;
  overflow: auto;
}

.title {
  width: 100%;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}

.stackCard {
  width: 100%;
  height: 90%;
}