.pgOne {
  display: flex;
  justify-content: space-between;
}

.exImg {
  height: 600px;
}

.fibText {
  margin: 40px 50px;
}

.fibDes {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.exDes {
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
}

.pgTwo {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}